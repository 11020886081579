import Vue from 'vue'
import vuetify from './vuetify.js'
import router from './router.js'
import i18n from 'i18n/index.js'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import TopMixin from './TopMixin.js'
import VueTimers from 'vue-timers'
import {provider as apolloProvider} from 'api/index.js'
import { usePwa } from './pwa/index.ts'
// import IdleVue from 'idle-vue'
import VueApexCharts from 'vue-apexcharts'

import store from 'store/index.js'

const { createPwa } = usePwa();
createPwa();

Vue.use(Vuelidate);
Vue.use(VueTimers);
// Vue.use(IdleVue, {store, startAtIdle: false, idleTime: 10000});

Vue.use(VueApexCharts);
Vue.component('apexcharts', VueApexCharts);

Vue.mixin(TopMixin);

// Temporary to deal with annoying console warning message from Vuetify
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg.startsWith(ignoreWarnMessage)) {
    msg = null;
    vm = null;
    trace = null;
  }
};

new Vue({
  // inject apolloProvider here like vue-router or vuex
  apolloProvider,
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
